var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$fetchState.pending)?_c('Loading'):_c('section',[_c('HarvesterUpgrade'),_vm._v(" "),_c('div',{staticClass:"cluster-dashboard-glance"},[_c('div',[_c('label',[_vm._v("\n        "+_vm._s(_vm.t('harvester.dashboard.version'))+":\n      ")]),_vm._v(" "),_c('span',[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({content: _vm.currentVersion}),expression:"{content: currentVersion}"}]},[_vm._v("\n          "+_vm._s(_vm.currentVersion)+"\n        ")])])]),_vm._v(" "),_c('div',[_c('label',[_vm._v("\n        "+_vm._s(_vm.t('glance.created'))+":\n      ")]),_vm._v(" "),_c('span',[_c('LiveDate',{attrs:{"value":_vm.firstNodeCreationTimestamp,"add-suffix":true,"show-tooltip":true}})],1)])]),_vm._v(" "),_c('div',{staticClass:"resource-gauges"},_vm._l((_vm.totalCountGaugeInput),function(resource){return _c('ResourceSummary',{key:resource.resource,attrs:{"spoofed-counts":resource.isSpoofed ? resource : null,"resource":resource.resource}})}),1),_vm._v(" "),(_vm.nodes.length && _vm.hasMetricNodeSchema)?[_c('h3',{staticClass:"mt-40"},[_vm._v("\n      "+_vm._s(_vm.t('clusterIndexPage.sections.capacity.label'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"hardware-resource-gauges",class:{
        live: !_vm.storageTotal,
      }},[_c('HardwareResourceGauge',{attrs:{"name":_vm.t('harvester.dashboard.hardwareResourceGauge.cpu'),"reserved":_vm.cpuReserved,"used":_vm.cpuUsed}}),_vm._v(" "),_c('HardwareResourceGauge',{attrs:{"name":_vm.t('harvester.dashboard.hardwareResourceGauge.memory'),"reserved":_vm.ramReserved,"used":_vm.ramUsed}}),_vm._v(" "),(_vm.storageTotal)?_c('HardwareResourceGauge',{attrs:{"name":_vm.t('harvester.dashboard.hardwareResourceGauge.storage'),"used":_vm.storageUsed,"reserved":_vm.storageReserved}}):_vm._e()],1)]:_vm._e(),_vm._v(" "),(_vm.hasMetricsTabs)?_c('Tabbed',{staticClass:"mt-30"},[(_vm.showClusterMetrics)?_c('Tab',{attrs:{"name":"cluster-metrics","label":_vm.t('clusterIndexPage.sections.clusterMetrics.label'),"weight":99},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.active)?_c('DashboardMetrics',{attrs:{"detail-url":_vm.CLUSTER_METRICS_DETAIL_URL,"summary-url":_vm.CLUSTER_METRICS_SUMMARY_URL,"graph-height":"825px"}}):_vm._e()]}}],null,false,1229087076)}):_vm._e(),_vm._v(" "),(_vm.showVmMetrics)?_c('Tab',{attrs:{"name":"vm-metric","label":_vm.t('harvester.dashboard.sections.vmMetrics.label'),"weight":98},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.active)?_c('DashboardMetrics',{attrs:{"detail-url":_vm.VM_DASHBOARD_METRICS_URL,"graph-height":"825px","has-summary-and-detail":false}}):_vm._e()]}}],null,false,1558151507)}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mb-40 mt-40"},[_c('h3',[_vm._v("\n      "+_vm._s(_vm.t('clusterIndexPage.sections.events.label'))+"\n    ")]),_vm._v(" "),_c('Tabbed',{staticClass:"mt-20"},[_c('Tab',{attrs:{"name":"host","label":"Hosts","weight":98}},[_c('SortableTable',{attrs:{"rows":_vm.hostEvents,"headers":_vm.eventHeaders,"key-field":"id","search":false,"table-actions":false,"row-actions":false,"paging":true,"rows-per-page":10,"default-sort-by":"date"},scopedSlots:_vm._u([{key:"cell:resource",fn:function(ref){
      var row = ref.row;
      var value = ref.value;
return [_c('div',{staticClass:"text-info"},[_vm._v("\n              "+_vm._s(value)+"\n            ")]),_vm._v(" "),(row.message)?_c('div',[_vm._v("\n              "+_vm._s(row.displayMessage)+"\n            ")]):_vm._e()]}}])})],1),_vm._v(" "),_c('Tab',{attrs:{"name":"vm","label":"VMs","weight":99}},[_c('SortableTable',{attrs:{"rows":_vm.vmEvents,"headers":_vm.eventHeaders,"key-field":"id","search":false,"table-actions":false,"row-actions":false,"paging":true,"rows-per-page":10,"default-sort-by":"date"},scopedSlots:_vm._u([{key:"cell:resource",fn:function(ref){
      var row = ref.row;
      var value = ref.value;
return [_c('div',{staticClass:"text-info"},[_vm._v("\n              "+_vm._s(value)+"\n            ")]),_vm._v(" "),(row.message)?_c('div',[_vm._v("\n              "+_vm._s(row.displayMessage)+"\n            ")]):_vm._e()]}}])})],1),_vm._v(" "),_c('Tab',{attrs:{"name":"volume","label":"Volumes","weight":97}},[_c('SortableTable',{attrs:{"rows":_vm.volumeEvents,"headers":_vm.eventHeaders,"key-field":"id","search":false,"table-actions":false,"row-actions":false,"paging":true,"rows-per-page":10,"default-sort-by":"date"},scopedSlots:_vm._u([{key:"cell:resource",fn:function(ref){
      var row = ref.row;
      var value = ref.value;
return [_c('div',{staticClass:"text-info"},[_vm._v("\n              "+_vm._s(value)+"\n            ")]),_vm._v(" "),(row.message)?_c('div',[_vm._v("\n              "+_vm._s(row.displayMessage)+"\n            ")]):_vm._e()]}}])})],1),_vm._v(" "),_c('Tab',{attrs:{"name":"image","label":"Images","weight":96}},[_c('SortableTable',{attrs:{"rows":_vm.imageEvents,"headers":_vm.eventHeaders,"key-field":"id","search":false,"table-actions":false,"row-actions":false,"paging":true,"rows-per-page":10,"default-sort-by":"date"},scopedSlots:_vm._u([{key:"cell:resource",fn:function(ref){
      var row = ref.row;
      var value = ref.value;
return [_c('div',{staticClass:"text-info"},[_vm._v("\n              "+_vm._s(value)+"\n            ")]),_vm._v(" "),(row.message)?_c('div',[_vm._v("\n              "+_vm._s(row.displayMessage)+"\n            ")]):_vm._e()]}}])})],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }