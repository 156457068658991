
import Vue, { PropType } from 'vue';
import { _EDIT, _VIEW } from '@shell/config/query-params';
import { addObject, removeObject } from '@shell/utils/array';

export default Vue.extend({
  props: {
    /**
     * The checkbox value.
     */
    value: {
      type:    [Boolean, Array] as PropType<boolean | boolean[]>,
      default: false
    },

    /**
     * The checkbox label.
     */
    label: {
      type:    String,
      default: null
    },

    /**
     * The i18n key to use for the checkbox label.
     */
    labelKey: {
      type:    String,
      default: null
    },

    /**
     * Random ID generated for binding label to input.
     */
    id: {
      type:    String,
      default: String(Math.random() * 1000)
    },

    /**
     * Disable the checkbox.
     */
    disabled: {
      type:    Boolean,
      default: false
    },

    /**
     * Display an indeterminate state. Useful for cases where a checkbox might 
     * be the parent to child checkboxes, and we need to show that a subset of 
     * children are checked.
     */
    indeterminate: {
      type:    Boolean,
      default: false
    },

    /**
     * The checkbox editing mode.
     * @values _EDIT, _VIEW
     */
    mode: {
      type:    String,
      default: _EDIT
    },

    /**
     * The contents of the checkbox tooltip.
     */
    tooltip: {
      type:    [String, Object],
      default: null
    },

    /**
     * The i18n key to use for the checkbox tooltip.
     */
    tooltipKey: {
      type:    String,
      default: null
    },

    /**
     * A custom value to use when the checkbox is checked.
     */
    valueWhenTrue: {
      type:    [Boolean, String, Number],
      default: true
    },

    /**
     * The i18n key to use for the checkbox description.
     */
    descriptionKey: {
      type:    String,
      default: null
    },

    /**
     * The checkbox description.
     */
    description: {
      type:    String,
      default: null
    },

    /**
     * Primary checkbox displays label so that it stands out more
     */
    primary: {
      type:    Boolean,
      default: false
    },    
  },

  computed: {
    /**
     * Determines if the checkbox is disabled.
     * @returns boolean: True when the disabled prop is true or when mode is 
     * View.
     */
    isDisabled(): boolean {
      return (this.disabled || this.mode === _VIEW);
    },
    /**
     * Determines if the checkbox is checked when using custom values or 
     * multiple values.
     * @returns boolean: True when at least one value is true in a collection or 
     * when value matches `this.valueWhenTrue`.
     */
    isChecked(): boolean {
      return this.isMulti(this.value) ? this.findTrueValues(this.value) : this.value === this.valueWhenTrue;
    }
  },

  methods: {
    /**
     * Toggles the checked state for the checkbox and emits an 'input' event.
     */
    clicked(event: MouseEvent): boolean | void {
      if ((event.target as HTMLLinkElement).tagName === 'A' && (event.target as HTMLLinkElement).href) {
        // Ignore links inside the checkbox label so you can click them
        return true;
      }

      event.stopPropagation();
      event.preventDefault();

      if (this.isDisabled) {
        return;
      }

      const customEvent = {
        bubbles:    true,
        cancelable: false,
        shiftKey:   event.shiftKey,
        altKey:     event.altKey,
        ctrlKey:    event.ctrlKey,
        metaKey:    event.metaKey
      };

      const click = new CustomEvent('click', customEvent);

      // Flip the value
      if (this.isMulti(this.value)) {
        if (this.isChecked) {
          removeObject(this.value, this.valueWhenTrue);
        } else {
          addObject(this.value, this.valueWhenTrue);
        }
        this.$emit('input', this.value);
      } else {
        this.$emit('input', !this.value);
        this.$el.dispatchEvent(click);
      }
    },

    /**
     * Determines if there are multiple values for the checkbox.
     */
    isMulti(value: boolean | boolean[]): value is boolean[] {
      return Array.isArray(value);
    },

    /**
     * Finds the first true value for multiple checkboxes.
     * @param value A collection of values for the checkbox.
     */
    findTrueValues(value: boolean[]): boolean {
      return value.find(v => v === this.valueWhenTrue) || false;
    }
  }
});
